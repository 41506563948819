<template>
  <div class="form">
    <div class="weui-cells__title">时间</div>
    <div class="weui-cells">
      <!-- <apicker
        :options="sex"
        text=""
        :model.sync="name"
        placeholder="请选择"
      ></apicker> -->
      <adate
        text="回国时间"
        :model.sync="form.returnTime"
        placeholder="请输入"
      ></adate>

      <ainput
        type="text"
        text="国内负责人"
        :model.sync="form.inManager"
        value=""
        placeholder="请输入"
      ></ainput>
    </div>
    <!-- 组1 -->
    <div class="weui-cells__title">紧急联系人</div>
    <div class="weui-cells">
      <ainput
        type="text"
        text="紧急联系人姓名"
        :model.sync="form.emergencyContact"
        value=""
        placeholder="请输入"
      ></ainput>
      <ainput
        type="text"
        text="紧急联系人电话"
        :model.sync="form.emergencyMobile"
        value=""
        placeholder="请输入"
      ></ainput>
    </div>
    <!-- 组2 -->
    <div class="weui-cells">
      <apicker text="常驻国家" :options="radios" :model.sync="form.inChina"></apicker>
      <apicker
          :options="countries"
          text="国家"
          :model.sync="form.alwaysCountry"
          placeholder="请选择"
        ></apicker>
      <acell text="详细地址">
        <span class="position" @click="mapFlag = true" v-if="form.inChina==0">
          <i class="el-icon-map-location"></i>
          地图</span
        >
      </acell>
      <ainput
        class="alwaysAddress"
        type="text"
        text=""
        :model.sync="form.addressDetail"
        placeholder=""
      ></ainput>
    </div>
    <div class="weui-cells">
      <adate
        text="预计返岗时间"
        :model.sync="form.workTime"
        placeholder="请选择"
      ></adate>
    </div>

    <div class="btn-area">
      <button class="weui-btn weui-btn_default" @click="goDetail">
        添加行程记录
      </button>
      <button class="weui-btn weui-btn_primary" @click="submit">确认</button>
    </div>

    <!-- 选择地图 -->
    <div v-if="mapFlag" class="map">
      <iframe
        id="mapPage"
        width="100%"
        height="100%"
        frameborder="0"
        allow="geolocation"
        src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=MLMBZ-JXJCW-TXVR3-OF7AG-ZD5CF-ONFBC&referer=myapp"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import adate from "@/components/form/datepicker";
import acell from "@/components/form/cell";
import location from "@/mixins/getLocation";
import { HomeSave , getAllcounrtyData} from "@/api/api";
import VDistpicker from "v-distpicker";
import time from "@/mixins/time";
import webview from "@/mixins/webview";
import aradio from "@/components/form/radio"
export default {
  name: "Home",
  data() {
    return {
      form: {
        mobile: this.$store.state.userInfo.mobile,
        returnTime: this.nowTime(),
        workTime: "",
        inManager: "",
        emergencyContact: "",
        emergencyMouble: "",
        province: "",
        city: "",
        district: "",
        street: "",
        addressDetail: "",
        inChina:0,
        alwaysCountry:'中国'
      },
      radios:[{
        label:'境内',
        value:0
      },{
        label:'境外',
        value:1
      }],
      mapFlag: false,
      countries:[{label:'中国',value:'中国'}]
    };
  },
  components: {
    ainput,
    apicker,
    adate,
    acell,
    VDistpicker,
    aradio
  },
  watch:{
    form:{
      handler:function(val){
        if(val.inChina==0){
          this.form.alwaysCountry = '中国'
        }else{
          this.form.alwaysCountry = ''
        }
      },
      deep:true
    }
  },
  mixins: [location, time, webview],
  mounted() {
    this.isWebView();
    let _this = this;
    this.province = this.form.province;
    this.city = this.form.city;
    this.district = this.form.district;
    this.getLocation();
    this.getCountries()
    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == "locationPicker") {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          _this.mapFlag = false;
          _this.form.addressDetail = loc.poiaddress + loc.poiname;
          _this.translateAddress(loc.latlng);
        }
      },
      false
    );
  },
  methods: {
    async getCountries(){
      let res = await getAllcounrtyData()
      this.countries = res.data.data.map(item=>{
        return{
          label:item.name,
          value:item.name
        }
      })
      this.countries.unshift({label:'中国',value:'中国'})
    },
    mapSelect(data) {
      this.province = data.province.value;
      this.city = data.city.value;
      this.district = data.area.value;
    },
    goDetail() {
      this.form = {
        ...this.form,
        ...{
          province: this.province,
          city: this.city,
          district: this.district,
        },
      };
      this.$store.commit("setCache", { key: "home", data: this.form });
      this.$router.push({ name: "Journey" });
    },
    submit() {
      this.form = {
        ...this.form,
        ...{
          province: this.province,
          city: this.city,
          district: this.district,
        },
      };
      let flag = true,
        tips = "";
      for (let k in this.form) {
        if (!this.form[k]) {
          switch (k) {
            case "returnTime":
              flag = false;
              tips = "回国时间为必填";
              break;
            case "inManager":
              flag = false;
              tips = "国内负责人为必填";
              break;
            case "emergencyContact":
              flag = false;
              tips = "紧急联系人姓名为必填";
              break;
            case "emergencyMobile":
              flag = false;
              tips = "紧急联系人电话为必填";
              break;
            case "addressDetail":
              flag = false;
              tips = "地址请填写完整";
              break;
          }
        }
      }
      if (flag) {
        HomeSave(this.form).then((res) => {
          this.$store.commit("clearCache", {});
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      } else {
        this.$weui.toast(tips, {
          duration: 1500,
          className: "error",
        });
      }
    },
  },
};
</script>

<style >
.position {
  color: #2f7dcd;
  margin-left: 0.1rem;
  width: 0.6rem;
  font-size: 0.12rem;
  background: #2f7dcd;
  color: #fff;
  padding: 3px 5px;
  text-align: center;
  border-radius: 5px;
}
</style>